<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 70px">
      <Toast
        v-if="toastData.show"
        :type="toastData.type"
        :message="toastData.message"
        :show="toastData.show"
        :time="10000"
        @clearToast="clearToast"
      />
      <Plan ref="savePlanModal" @saveModel="saveModel($event)" />
      <main class="page-content">
        <div class="container-fluid">
          <ul class="breadcrumb ProximaNovaRegular mx-4">
            <li class="ProximaNovaBold">Model results</li>
          </ul>
          <div style="justify-content: end; display: flex; font-size: small;">
            <span>{{ message }}</span>
          </div>
          <div class="mr-2" v-if="plans.length !== 0">
            <w-button
              class="mr-2 "
              @buttonClicked="
                $refs.savePlanModal.open({
                  action: 'add',
                  modelList: dropdownList,
                })
              "
              :buttonText="'Save Model'"
              style="width: auto;"
            >
            </w-button>
          </div>
        </div>

        <b-container fluid class="my-5">
          <!--<b-row v-if="plans.length === 0 || clientId !== ''"> -->
          <b-row v-if="plans.length === 0">
            <skeleton v-for="index in 3" :key="index" />
          </b-row>
          <b-row v-else>
            <b-col
              class="mb-5 h-100"
              v-for="plan in plans"
              :key="plan.id"
              :class="[plan.id !== activePlan ? 'mt-50' : '']"
              cols="12"
              lg="4"
            >
              <div class="Mask">
                <div class="mask-title">{{ plan.title }}</div>
                <div
                  :class="[
                    plan.id == activePlan
                      ? 'active-mask-subtitle'
                      : 'mask-subtitle',
                  ]"
                >
                  <span v-if="plan.name === recommendedModal"
                    >Our recommended model</span
                  >
                </div>
                <img :src="plan.imageUrl" class="mask-image" />
                <div class="mask-list">
                  <ul class="p-0">
                    <li
                      v-for="(stats, index) in plan.statics"
                      :key="index"
                      class="d-flex justify-content-between mb-5"
                    >
                      <div class="mask-list-item">
                        <i class="far fa-check-circle small mr-3"></i
                        >{{ stats.name }}
                      </div>
                      <div class="mask-list-score">
                        {{ stats.value }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="mask-cta text-right">
                  <button
                    color="primary isi"
                    class="btn-effect"
                    size="button--size-m"
                    @click="selectActivePlan(plan)"
                    :class="[
                      plan.id == activePlan
                        ? 'active-btn-effect'
                        : 'inActive-btn-effect',
                    ]"
                  >
                    Select<i
                      class="fas fa-arrow-right ml-5 pl-4 d-flex"
                      style="align-items: center"
                    ></i>
                  </button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </main>
    </div>
  </div>
</template>

<script>
import { AIDigitalPoem } from "@/services/AIDigitalPoemServices";
const aiDigitalPoem = new AIDigitalPoem();
import { abbreviateNumber } from "js-abbreviation-number";
import Skeleton from "@/widgets/ModalResultCardSkeleton.vue";
import Toast from "@/components/Toast/Toast.vue";
import Button from "@/components/Profile/Button.vue";
import Plan from "@/components/Modal/SaveModelModal.vue";
var timeOutStatus;
export default {
  name: "results",
  components: {
    skeleton: Skeleton,
    Toast,
    "w-button": Button,
    Plan,
  },
  data() {
    return {
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      activePlan: 2,
      recommendedModal: "",
      dropdownList: [],
      plans: [],
      message: "",
      stopUpdate: false,
      clientId: "",
    };
  },
  beforeDestroy() {
    clearInterval(timeOutStatus);
  },
  beforeCreate() {
    clearInterval(timeOutStatus);
  },
  methods: {
    selectActivePlan(plan) {
      if (plan.id === this.activePlan) {
        sessionStorage.setItem("selectedPlan", JSON.stringify(plan.statics));
        sessionStorage.setItem("modalList", JSON.stringify(this.dropdownList));
        sessionStorage.setItem(
          "activeModalName",
          JSON.stringify({ id: plan.name, text: plan.title })
        );
        this.$router.push("/solutions/digital-poem/stats");
      } else {
        this.activePlan = plan.id;
      }
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    updatePlan() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, Sit back and relax will notify you once done",
      };
    },
    saveModel(e) {
      aiDigitalPoem.saveModel(e).then((res) => {
        console.log(res);
        if (res === "Model already saved") {
          this.toastData.show = true;
          this.toastData.type = "failed";
          this.toastData.message = "This model is allready saved";
        } else {
          this.savePlan();
        }
      });
    },
    savePlan() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, Our system has saved you model successfuly",
      };
    },
    updateStatus() {
      clearInterval(timeOutStatus);
      var self = this;
      timeOutStatus = setInterval(function() {
        if (self.stopUpdate) {
          clearInterval(timeOutStatus);
        } else {
          self.getStatus();
          clearInterval(timeOutStatus);
        }
      }, 3000);
    },
    getStatus() {
      aiDigitalPoem.getStatus(sessionStorage.getItem("runId")).then((res) => {
        if (res.status.preProcess.status === "started") {
          this.message = "(1/3) we are learning your data";
        } else if (
          res.status.preProcess.status === "error" ||
          res.status.attribution.status === "error" ||
          res.status.optimization.status === "error"
        ) {
          this.message = "Something went wrong Please try after sometime";
          this.stopUpdate = true;
        } else if (res.status.attribution.status === "started") {
          this.message = "(2/3) we are training your data";
        } else if (res.status.optimization.status === "started") {
          if (this.plans.length === 0) {
            this.getModalSummary();
          }
          this.message = "(3/3) we are optimizing data";
        } else if (res.status.optimization.status === "ended") {
          this.stopUpdate = true;
          this.message = "Results are ready";
          // this.message = ""
          if (this.plans.length === 0) {
            this.getModalSummary();
          }
          var self = this;
          setTimeout(function() {
            self.message = "";
          }, 5000);
        }
        this.updateStatus();
      });
    },
    getModalSummary() {
      aiDigitalPoem
        .getAllModals({ uniqueId: sessionStorage.getItem("runId"),model: null })
        .then((res) => {
          this.recommendedModal = res.response["recommended Model"];
          this.plans = [];
          this.dropdownList = [];
          var images = [
            "group-28.png",
            "group-26.png",
            "group-29.png",
            "group-28.png",
            "group-26.png",
            "group-29.png",
          ];
          for (var i = 0; i < res.response.values.length; i++) {
            var data = [];
            for (var j = 0; j < res.response.values[i].statics.length; j++) {
              data.push({
                name: res.response.values[i].statics[j].name,
                value: this.orgnizeNumber(
                  res.response.values[i].statics[j].value
                ),
              });
            }
            this.plans.push({
              id: res.response.values[i].id,
              title: res.response.values[i].name,
              subtitle: "",
              imageUrl: require("@/assets/" + images[i]),
              statics: data,
              name: res.response.values[i].title,
            });
            if (
              res.response.values[i].title === res.response["recommended Model"]
            ) {
              this.activePlan = res.response.values[i].id;
            }
            this.dropdownList.push({
              id: res.response.values[i].title,
              text: res.response.values[i].name,
            });
          }
        });
    },
  },
  computed: {
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data, 1);
        } else {
          return Math.round(data * 100) / 100;
        }
      };
    },
  },
  created() {
    this.clientId = sessionStorage.getItem("clientId");
    // this.getStatus();
    this.getModalSummary()
  },
};
</script>

<style lang="css" scoped>
.container {
  height: 100%;
}
.breadcrumb {
  background-color: transparent;
  margin-top: 32px;
}

.Mask {
  max-width: 456px;
  width: 100%;
  height: 100%;
  padding: 32px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.mt-50 {
  margin-top: 50px;
}

.mask-title {
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #222a37;
}
.mask-subtitle {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
}
.mask-list {
  margin-top: 76px;
}
.mask-list .mask-list-item {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #222a37;
}
.mask-list .mask-list-score {
  font-family: ProximaNovaBold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #222a37;
}

.mask-image {
  top: -9%;
  right: -9%;
  position: absolute;
  z-index: -1;
}

.active-mask-subtitle {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #55ea76;
}

.btn-effect {
  /* margin: 70px 0px 20px 65px; */
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  border: 2px solid black;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  border-radius: 5px;
  background-color: white;
}

.inActive-btn-effect {
  background-color: white;
}

.active-btn-effect {
  background-color: #050505;
  color: #f3f3f3;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover {
  background-color: #050505;
  color: #f3f3f3;
}
</style>

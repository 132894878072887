var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-wrapper chiller-theme",staticStyle:{"margin-top":"70px"}},[(_vm.toastData.show)?_c('Toast',{attrs:{"type":_vm.toastData.type,"message":_vm.toastData.message,"show":_vm.toastData.show,"time":10000},on:{"clearToast":_vm.clearToast}}):_vm._e(),_c('Plan',{ref:"savePlanModal",on:{"saveModel":function($event){return _vm.saveModel($event)}}}),_c('main',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticStyle:{"justify-content":"end","display":"flex","font-size":"small"}},[_c('span',[_vm._v(_vm._s(_vm.message))])]),(_vm.plans.length !== 0)?_c('div',{staticClass:"mr-2"},[_c('w-button',{staticClass:"mr-2 ",staticStyle:{"width":"auto"},attrs:{"buttonText":'Save Model'},on:{"buttonClicked":function($event){return _vm.$refs.savePlanModal.open({
                action: 'add',
                modelList: _vm.dropdownList,
              })}}})],1):_vm._e()]),_c('b-container',{staticClass:"my-5",attrs:{"fluid":""}},[(_vm.plans.length === 0)?_c('b-row',_vm._l((3),function(index){return _c('skeleton',{key:index})}),1):_c('b-row',_vm._l((_vm.plans),function(plan){return _c('b-col',{key:plan.id,staticClass:"mb-5 h-100",class:[plan.id !== _vm.activePlan ? 'mt-50' : ''],attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"Mask"},[_c('div',{staticClass:"mask-title"},[_vm._v(_vm._s(plan.title))]),_c('div',{class:[
                  plan.id == _vm.activePlan
                    ? 'active-mask-subtitle'
                    : 'mask-subtitle' ]},[(plan.name === _vm.recommendedModal)?_c('span',[_vm._v("Our recommended model")]):_vm._e()]),_c('img',{staticClass:"mask-image",attrs:{"src":plan.imageUrl}}),_c('div',{staticClass:"mask-list"},[_c('ul',{staticClass:"p-0"},_vm._l((plan.statics),function(stats,index){return _c('li',{key:index,staticClass:"d-flex justify-content-between mb-5"},[_c('div',{staticClass:"mask-list-item"},[_c('i',{staticClass:"far fa-check-circle small mr-3"}),_vm._v(_vm._s(stats.name)+" ")]),_c('div',{staticClass:"mask-list-score"},[_vm._v(" "+_vm._s(stats.value)+" ")])])}),0)]),_c('div',{staticClass:"mask-cta text-right"},[_c('button',{staticClass:"btn-effect",class:[
                    plan.id == _vm.activePlan
                      ? 'active-btn-effect'
                      : 'inActive-btn-effect' ],attrs:{"color":"primary isi","size":"button--size-m"},on:{"click":function($event){return _vm.selectActivePlan(plan)}}},[_vm._v(" Select"),_c('i',{staticClass:"fas fa-arrow-right ml-5 pl-4 d-flex",staticStyle:{"align-items":"center"}})])])])])}),1)],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"breadcrumb ProximaNovaRegular mx-4"},[_c('li',{staticClass:"ProximaNovaBold"},[_vm._v("Model results")])])}]

export { render, staticRenderFns }